<template>
  <div class="container">
      <div class="search-contain">
        <div class="search-item">
          <el-date-picker
            :value="post.time"
            @input="changeTime1(arguments[0])"
            type="daterange"
            align="right"
            unlink-panels
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :pickerOptions="pickerOptions"
          ></el-date-picker>
        </div>
        <div class="search-item mr20">
          <el-select v-model="post.page_type">
            <el-option v-for="item in courseOpt" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
      <pagination2
        :option="post"
        url="/statistics/visitHistory"
        ref="childDialogBox"
      >
        <template v-slot:default="{ tableData }">
          <div class="pagination2">
            <el-table
              ref="dialogTable"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData.list"
              style="width: 100%"
            >
              <el-table-column label="id" prop="uid">
                <template slot-scope="{ row }">
                  <span>{{ row['uid'] }}</span>
                </template>
              </el-table-column>
              <el-table-column label="学员" prop="uname">
                <template slot-scope="{ row }">
                  <span class="cp">{{ row['uname'] }}</span>
                </template>
              </el-table-column>
              <el-table-column label="访问时间" prop="c_time">
                <template slot-scope="{ row }">
                  <span class="cp">{{ row['c_time']| formatTimeStamp('yyyy-MM-dd hh:mm') }}</span>
                </template>
              </el-table-column>
              <el-table-column label="访问课程/直播" prop="course_name">
                <template slot-scope="{ row }">
                  <span class="cp">{{ row['course_name'] }}</span>
                </template>
              </el-table-column>
              <el-table-column label="类型" prop="page_type">
                <template slot-scope="{ row }">
                  <!-- 1 h5 2 小程序 3 PC浏览器 -->
                  <span v-if="row.page_type == 1">微信H5</span>
                  <span v-if="row.page_type == 2">小程序</span>
                  <span v-if="row.page_type == 3">电脑</span>
                </template>
              </el-table-column>
              <el-table-column label="停留时长" prop="duration">
                <template slot-scope="{ row }">
                  <span class="cp">{{ row['duration'] }}s</span>
                </template>
              </el-table-column>
              <el-table-column label="访问来源" prop="user_from">
                <template slot-scope="{ row }">
                <!-- 1 h5 2 小程序 3 PC浏览器 -->
                <span v-if="row.user_from == 1">微信H5</span>
                <span v-if="row.user_from == 2">小程序</span>
                <span v-if="row.user_from == 3">电脑</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
  </div>
</template>

<script>
export default {
  name: 'records',
  data: () => ({
    pickerOptions: {
      disabledDate(time) {
        return time.getTime() > Date.now()
      }
    },
    post: {
      page_type:0,
      time: '',
    },
    courseOpt: [
      {
        name: '全部',
        id: 0
      },
      {
        name: '访问网校',
        id: 1
      },
      {
        name: '访问课程',
        id: 2
      },
      {
        name: '访问直播',
        id: 3
      }
    ]
  }),
  methods: {
    // 选择日期
    changeTime1(value, name = 'post') {
      this[name] = Object.assign({}, this[name], {
        startTime: value ? value[0] / 1000 : '',
        endTime: value ? value[1] / 1000 : '',
        time: value || '',
      })
    },
  },
  mounted(){
    console.log('this.$route.query',this.$route.query);
    this.post = Object.assign({}, this.post, this.$route.query,{
      time: [this.$route.query.startTime*1000,this.$route.query.endTime*1000]
    })
  },
}
</script>

<style lang="scss" scoped>
.container {
  background: #fff;
  margin: 20px 20px;
  padding: 20px;
  border-top: 1px solid #fff;
  .search-contain {
    display: flex;
    flex-direction: row-reverse;
    .search-item {
      margin-bottom: 30px;
    }
  }
}
::v-deep .el-select,
::v-deep .el-input__inner,
::v-deep .el-date-editor {
  height: 36px;
}
::v-deep .el-select {
  width: 120px;
}
</style>